import { useEffect, useState } from 'react';
import './RegisterWrapperUK.css';
import { ExternalStyles, RegisterUK, Config } from '@synlab-access/shfy-registration';
import { constants } from '../constants';

export function RegisterWrapperUK() {
    const [config, setConfig] = useState<Config | null>(null);

    useEffect(() => {
        fetch('/api/environment').then(async response => {
            const body = await response.json();
            let environment = 'stage.';
            if (body.Environment === constants.STAGE_ENVIRONMENT) {
                environment = 'stage.';
            }
            if (body.Environment === constants.DEV_ENVIRONMENT) {
                environment = 'dev.';
            }
            setConfig({
                authBaseUrl: `https://${environment}shfy.sts.synlab.co.uk`,
                accountBaseUrl: `https://${environment}shfy.accounts.synlab.co.uk`,
                translation: 'en-us',
                privacyPolicyUrl: `https://${environment}shfy.accounts.synlab.co.uk/privacy-policy?langCode=it`,
                websiteTermsUrl: 'https://shop.synlab.co.uk/legal-policies/website-terms-of-use',
                homePageUrl: environment === 'stage.' ? 'https://uk.staging.shfydigital.com' : 'https://uk.ndev.shfydigital.com',
                completeProfileUrl: `https://${environment}viewer.results.synlab.com/complete-profile-uk`
            });
        });
    }, []);

    return  <>
        <ExternalStyles />
        {
            config ? <RegisterUK config={{...config}} /> : null
        }
    </>
}