import './App.css';
import '@synlab-access/shfy-registration/styles.css';
import { Routes, Route } from 'react-router-dom';
import { RegisterWrapperUK } from './register-wrapper-uk/RegisterWrapperUK';
import { RegisterWrapperIT } from './register-wrapper-it/RegisterWrapperIT';
import { CompleteProfileWrapperIT } from './complete-profile-wrapper-it/CompleteProfileWrapperIT';
import { CompleteProfileWrapperUK } from './complete-profile-wrapper-uk/CompleteProfileWrapperUK';
import { Navigation } from './navigation/Navigation';

function App() {
  return <div className="App">
    <Routes>
      <Route path='/' element={<Navigation />}></Route>
      <Route path='/register-uk' element={<RegisterWrapperUK />}></Route>
      <Route path='/register-it' element={<RegisterWrapperIT />}></Route>
      <Route path='/complete-profile-uk' element={<CompleteProfileWrapperUK />}></Route>
      <Route path='/complete-profile-it' element={<CompleteProfileWrapperIT />}></Route>
    </Routes>
  </div>
}

export default App;
