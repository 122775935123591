import { Link } from 'react-router-dom';

export function Navigation() {
    return <ul>
        <li>
            <Link to='/register-uk'>Register UK</Link>
        </li>
        <li>
            <Link to='/register-it'>Register IT</Link>
        </li>
        <li>
            <Link to='/complete-profile-uk'>Complete Profile UK</Link>
        </li>
        <li>
            <Link to='/complete-profile-it'>Complete Profile IT</Link>
        </li>
    </ul>
}