import { useEffect, useState } from 'react';
import './CompleteProfileWrapperIT.css';
import { CompleteProfileIT, Config, ExternalStyles, InputGroup } from '@synlab-access/shfy-registration';
import { constants } from '../constants';

function getDevToken() {
    let obj: any = {};
    let queryString = '';
    if (typeof window !== "undefined") {
        obj = window;
        queryString = obj.location.search;
    }
    const bearer = new URLSearchParams(queryString).get('dev-token') || '';
    const token = bearer.split(' ');
    return token[1];
}

export function CompleteProfileWrapperIT() {
    const [token, setToken] = useState(getDevToken() || '');
    const [config, setConfig] = useState<Config | null>(null);

    useEffect(() => {
        fetch('/api/environment').then(async response => {
            const body = await response.json();
            let environment = 'stage.';
            if (body.Environment === constants.STAGE_ENVIRONMENT) {
                environment = 'stage.';
            }
            if (body.Environment === constants.DEV_ENVIRONMENT) {
                environment = 'dev.';
            }
            setConfig({
                authBaseUrl: `https://${environment}shfy.sts.synlab.it`,
                accountBaseUrl: `https://${environment}shfy.accounts.synlab.it`,
                translation: 'it',
                onSuccess: () => console.log('complete profile success callback')
            });
        });
    }, []);

    return <>
        { !token ? <InputGroup onChange={event => setToken(event.target.value)} placeholder="Access token" /> : null }
        { token ? <button onClick={() =>setToken('')}>Remove Token</button> : null }

        <ExternalStyles />

        { (token && config) ? <CompleteProfileIT token={token} config={{...config}} /> : null }
    </>
}
