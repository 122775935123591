import { Config, ExternalStyles, RegisterIT } from '@synlab-access/shfy-registration';
import './RegisterWrapperIT.css';
import { useEffect, useState } from 'react';
import { constants } from '../constants';

export function RegisterWrapperIT() {
    const [config, setConfig] = useState<Config | null>(null);

    useEffect(() => {
        fetch('/api/environment').then(async response => {
            const body = await response.json();
            let environment = 'stage.';
            if (body.Environment === constants.STAGE_ENVIRONMENT) {
                environment = 'stage.';
            }
            if (body.Environment === constants.DEV_ENVIRONMENT) {
                environment = 'dev.';
            }
            setConfig({
                authBaseUrl: `https://${environment}shfy.sts.synlab.it`,
                accountBaseUrl: `https://${environment}shfy.accounts.synlab.it`,
                translation: 'it',
                privacyPolicyUrl: `https://${environment}shfy.accounts.synlab.it/privacy-policy?langCode=it`,
                websiteTermsUrl: 'https://shop.synlab.it/legal-policies/website-terms-of-use',
                homePageUrl: environment === 'stage.' ? 'https://it.staging.shfydigital.com' : 'https://it.ndev.shfydigital.com',
                completeProfileUrl: `https://${environment}viewer.results.synlab.com/complete-profile-it`
            });
        });
    }, []);

    return <>
        <ExternalStyles />
        {
            config ? <RegisterIT config={{...config}} /> : null
        }
    </>
}
